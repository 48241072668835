import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from '../../utils/utils';
import { IRootState } from '../../store/_root.reducer';
import { IAppState, setClipBoard } from '../../store/app.reducer';

import './copy-to-clipboard.scss';

interface ICopyToClipboardProps extends StateProps, DispatchProps {
    value: IAppState['clipBoard'];
}

export const CopyToClipboard: FunctionComponent<ICopyToClipboardProps> = props => {
    const { t } = useTranslation();
    const { value: initialValue, clipBoard } = props;
    const input = useRef(null);
    const [value] = useState(initialValue);
    const [success, setSuccess] = useState(false);

    const handleCopy = () => {
        const current = input.current;
        if (current) {
            copyToClipboard(current);
            props.setClipBoard(value);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        }
    };

    useEffect(() => {
        if (clipBoard !== value) {
            setSuccess(false);
        }
    }, [clipBoard]);

    return (
        <section className={`CopyToClipboard`}>
            <input type="text" ref={input} value={value} />
            <Tooltip title={`${t(success ? 'copyToClipBoard.success' : 'copyToClipBoard.tooltip')}`} placement="top">
                <span onClick={handleCopy}>{success ? <LibraryAddCheckIcon color="primary" /> : <LibraryBooksIcon color="action" />}</span>
            </Tooltip>
        </section>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({
    clipBoard: app.clipBoard
});

const mapDispatchToProps = {
    setClipBoard
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CopyToClipboard);
