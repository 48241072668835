import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { StudentSessions } from './studentSessions/studentSessions';
import { TableHeadCell } from '../table/enhanced-table-head';
import { asCredits, asDateTime, asDuration } from '../../utils/utils';
import Table from '../table/table';
import { ISession } from '../../models/models';

import './sessions.scss';

interface SessionCell extends TableHeadCell {
    key: keyof ISession;
}

interface ISessionsProps {
    sessions?: ISession[];
    title?: string;
}

export const Sessions: FunctionComponent<ISessionsProps> = props => {
    const { t } = useTranslation();
    const { sessions, title } = props;

    const headCells: SessionCell[] = [
        { key: 'teacher_session_id', label: t(`session.teacher_session_id`), options: { isNumeric: true } },
        { key: 'number_of_students', label: t(`Students`), options: { isNumeric: true } },
        { key: 'credits_cost', label: t(`session.credits_cost`), options: { format: asCredits, isNumeric: true } },
        { key: 'duration', label: t(`session.duration`), options: { format: asDuration(), isNumeric: true } },
        { key: 'start_time', label: t(`session.start_time`), options: { format: asDateTime() } },
        { key: 'end_time', label: t(`session.end_time`), options: { format: asDateTime() } }
    ];

    const rows = (sessions || []).map(session => ({
        ...session,
        _id: `${session.teacher_session_id}`,
        _options: {
            collapsables: [
                ...(session.number_of_students
                    ? [
                          {
                              key: 'number_of_students',
                              element: <StudentSessions teacherSessionId={session.teacher_session_id} />
                          }
                      ]
                    : [])
            ]
        }
    }));

    return (
        <section className={`Sessions`}>
            {sessions && (
                <Table title={title || t(`sessions.title`)} noDataMessage={t(`sessions.noDataMessage`)} headCells={headCells} rows={rows} />
            )}
        </section>
    );
};
