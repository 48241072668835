import React, { FunctionComponent } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IClubDashboard } from '../../models/club-models';
import { Sessions } from '../../shared/sessions/sessions';
import { Teachers } from './teachers/teachers';
import { Transactions } from '../../shared/transactions/transactions';
import { getSearchParams } from '../../utils/utils';
import ClubTeacherDashboard from './club-teacher-dashboard';
import { DuplicateGames } from '../../shared/duplicate-games/duplicate-games';

import './club-dashboard.scss';

interface IClubDashboardProps extends RouteComponentProps {
    data: IClubDashboard;
}

export const ClubDashboard: FunctionComponent<IClubDashboardProps> = props => {
    const { t } = useTranslation();
    const { data, location } = props;
    const { teacherId } = getSearchParams(location.search);

    const renderContent = () => {
        switch (true) {
            case !!teacherId: {
                const teacher = data.teachers.find(teacher => `${teacher.teacherid}` === `${teacherId}`);
                if (teacher) {
                    return <ClubTeacherDashboard data={data} teacher={teacher} />;
                } else {
                    return <Redirect to="/dashboard" />;
                }
            }
            default: {
                return data ? (
                    <>
                        <Sessions sessions={data.teacher_sessions} title={t('club.sessions.title')} />
                        <Transactions transactions={data.transactions} title={t('club.transactions.title')} />
                        <Teachers teachers={data.teachers} />
                        <DuplicateGames duplicateGames={data.games ?? []} />
                    </>
                ) : null;
            }
        }
    };

    return <section className={`ClubDashboard`}>{renderContent()}</section>;
};
export default withRouter(ClubDashboard);
