import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@material-ui/core';
import useAxios from 'axios-hooks';
import qs from 'querystring';
import { connect } from 'react-redux';

import { Translate } from '../../utils/translate';
import { IRootState } from '../../store/_root.reducer';

import './request-teachers-account.scss';
import { CUSTOMER_TYPE } from '../../store/app.reducer';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Loading } from '../loading/loading';

interface IRequestTeachersAccountProps extends StateProps, DispatchProps, RouteComponentProps {}

export const RequestTeachersAccount: FunctionComponent<IRequestTeachersAccountProps> = props => {
    const { customer, history } = props;
    const { t } = useTranslation();
    const [message, setMessage] = useState<string>('');

    const [{ loading, data, error }, executeRequestTeacher] = useAxios(
        {
            url: 'requestteacher.php',
            method: 'POST'
        },
        { manual: true }
    );

    function handleRequestTeacher() {
        executeRequestTeacher({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                message
            })
        });
    }

    if (customer && ![CUSTOMER_TYPE.customer].includes(customer?.customer_type)) {
        return <Redirect to="/dashboard" />;
    }

    const render = () => {
        switch (true) {
            case loading:
                return <Loading />;
            case !!data:
                return (
                    <div className="success">
                        <Translate contentKey="requestTeachersAccount.success" />
                        <Button variant="contained" onClick={() => history.push('/dashboard')}>
                            <Translate contentKey="requestTeachersAccount.toDashboard" />
                        </Button>
                    </div>
                );
            default:
                return (
                    <>
                        <h1>
                            <Translate contentKey="requestTeachersAccount.headline" />
                        </h1>
                        <TextField
                            id="aboutYou"
                            label={t('requestTeachersAccount.placeholder')}
                            multiline
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                        />
                        <Button variant="contained" color="primary" onClick={handleRequestTeacher}>
                            <Translate contentKey="requestTeachersAccount.action" />
                        </Button>
                        {!!error && (
                            <div className="error">
                                <Translate contentKey="requestTeachersAccount.error" />
                            </div>
                        )}
                    </>
                );
        }
    };

    return <section className={`RequestTeachersAccount`}>{render()}</section>;
};

const mapStateToProps = ({ app }: IRootState) => ({
    customer: app.customer
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RequestTeachersAccount));
