import { Button, Link } from '@material-ui/core';
import qs from 'querystring';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import useAxios from 'axios-hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { IRootState } from '../../store/_root.reducer';
import { CUSTOMER_TYPE } from '../../store/app.reducer';
import { Loading } from '../loading/loading';

import './order-credits.scss';

declare const Stripe: any;

interface StripeCredentials {
    session_id: string;
    stripe_key: string;
}

interface IOrderCreditsProps extends StateProps, DispatchProps {}

export const OrderCredits: FunctionComponent<IOrderCreditsProps> = props => {
    const { stripe_account, customer_type } = props;
    const { t } = useTranslation();
    const stripeUrls: { [customerType: string]: string } = {
        [CUSTOMER_TYPE.club]: 'https://api.onlinebridgeclub.com/club/stripecheckout.php',
        [CUSTOMER_TYPE.teacher]: 'https://api.onlinebridgeclub.com/teacher/stripecheckout.php'
    };
    const [{ loading, data }, fetchStripeCredentials] = useAxios<StripeCredentials>(
        {
            url: stripeUrls[customer_type || ''],
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        if (data) {
            const stripe = Stripe(data.stripe_key);
            stripe.redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as argument here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: data.session_id
                // successUrl: window.location.href,
                // cancelUrl: window.location.href,
            });
        }
    }, [data]);

    const RenderOrderButton = (props: any) => {
        return (
            <Button variant="contained" color="secondary" className="orderCredits" startIcon={<ShoppingCartIcon />} {...props}>
                {t('teacher.dashboard.orderCredits')}
            </Button>
        );
    };

    const RenderOrderWithStripe = () => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const products = [
            {
                productId: 'SB50',
                credits: '50',
                icon: '50'
            },
            {
                productId: 'SB200',
                credits: '200',
                icon: '210'
            },
            {
                productId: 'SB500',
                credits: '500',
                icon: '550'
            },
            {
                productId: 'SB1000',
                credits: '1000',
                icon: '1200'
            }
        ];

        return (
            <div className={`orderWithStripe ${loading && 'loading'}`}>
                {loading && <Loading />}
                <RenderOrderButton aria-controls="select-product" aria-haspopup="true" onClick={handleClick} />
                <Menu id="select-product" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    {products.map(product => (
                        <MenuItem
                            key={product.productId}
                            onClick={() => fetchStripeCredentials({ data: qs.stringify({ product: product.productId }) })}
                            className="orderStripeCredits"
                            style={{ backgroundImage: `url('/images/Logo_SB_icon_${product.icon}_SB.svg')` }}
                        >
                            {t('credits.order', { credits: product.credits })}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    };

    return (
        <section className="OrderCredits">
            {stripe_account ? (
                <RenderOrderWithStripe />
            ) : (
                <Link href="https://www.thesharkbridgecompany.com/collections/order-sb-credits" target="_blank">
                    <RenderOrderButton />
                </Link>
            )}
        </section>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({
    customer_type: app.customer?.customer_type,
    stripe_account: app.customer?.stripe_account
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderCredits);
