import { Tooltip, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import './credits.scss';
import { useTranslation } from 'react-i18next';

interface IHeaderProps {
    credits: number;
}

export const Credits: FunctionComponent<IHeaderProps> = props => {
    const { t } = useTranslation();
    const { credits } = props;

    return (
        <section className={`Credits`}>
            <Tooltip title={`${t('credits.tooltip', { credits })}`}>
                <Typography className="credits">{credits}</Typography>
            </Tooltip>
        </section>
    );
};

export default Credits;
