import { Anchor } from '../shared/drawer/drawer';

const fontSizeStep = 5;
const minFontSize = 20;

export type FontSizeChange = '+' | '-' | 'default';

export enum CUSTOMER_TYPE {
    teacher = 'teacher',
    club = 'club',
    customer = 'customer'
}

export interface Customer {
    credits: number;
    customer_id: number;
    customer_type: CUSTOMER_TYPE;
    email: string;
    firstname: string;
    free_teacher: 0 | 1;
    isVIP: boolean;
    lastname: string;
    nickname: string;
    stripe_account: 0 | 1;
    teacher_club_id: number;
}

export const ACTION_TYPES = {
    REMOVE_CUSTOMER: 'app/REMOVE_CUSTOMER',
    RESET: 'app/RESET',
    SET_CLIP_BOARD: 'app/SET_CLIP_BOARD',
    SET_CUSTOMER: 'app/SET_CUSTOMER',
    SET_DRAWER_STATE: 'app/SET_DRAWER_STATE',
    SET_FONT_SIZE: 'app/SET_FONT_SIZE',
    SET_TABLE_OPTION: 'app/SET_DENSE_TABLES',
    SET_TABLE_ROWS_PER_PAGE: 'app/SET_TABLE_ROWS_PER_PAGE'
};

export interface IAppState {
    clipBoard?: string | number;
    customer?: Customer;
    drawer: {
        anchor: Anchor;
        isOpen: boolean;
    };
    fontSize: number;
    tableOptions: {
        isDense: boolean;
        rowsPerPage: number;
    };
    token?: string;
}

export const initialState: IAppState = {
    clipBoard: undefined,
    customer: undefined,
    drawer: {
        anchor: 'left',
        isOpen: false
    },
    fontSize: minFontSize,
    tableOptions: {
        isDense: false,
        rowsPerPage: 5
    },
    token: undefined
};

// Reducer

export default (state: IAppState = initialState, action: any): IAppState => {
    switch (action.type) {
        case ACTION_TYPES.SET_TABLE_OPTION:
            const tableOption: Partial<IAppState['tableOptions']> = action.payload;
            return {
                ...state,
                tableOptions: {
                    ...state.tableOptions,
                    ...tableOption
                }
            };
        case ACTION_TYPES.SET_FONT_SIZE:
            const { fontSizeChange } = action.payload;
            return {
                ...state,
                fontSize:
                    fontSizeChange === '+'
                        ? state.fontSize + fontSizeStep
                        : fontSizeChange === '-'
                        ? Math.max(minFontSize, state.fontSize - fontSizeStep)
                        : minFontSize
            };
        case ACTION_TYPES.SET_DRAWER_STATE:
            const drawerPartial: Partial<IAppState['drawer']> = action.payload;
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    ...drawerPartial
                }
            };
        case ACTION_TYPES.REMOVE_CUSTOMER:
            return {
                ...state,
                customer: initialState.customer,
                token: initialState.token
            };
        case ACTION_TYPES.SET_CUSTOMER:
            const { customer, token }: { customer: IAppState['customer']; token: IAppState['token'] } = action.payload;
            return {
                ...state,
                customer,
                token
            };
        case ACTION_TYPES.SET_CLIP_BOARD:
            return {
                ...state,
                clipBoard: action.payload
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

// Actions

const removeCustomer = () => ({
    type: ACTION_TYPES.REMOVE_CUSTOMER
});

const resetApp = () => ({
    type: ACTION_TYPES.RESET
});

const setTableOption = (tableOption: Partial<IAppState['tableOptions']>) => ({
    type: ACTION_TYPES.SET_TABLE_OPTION,
    payload: tableOption
});

const setDrawerState = (drawerPartial: Partial<IAppState['drawer']>) => ({
    type: ACTION_TYPES.SET_DRAWER_STATE,
    payload: drawerPartial
});

const setFontSize = (fontSizeChange: FontSizeChange) => ({
    type: ACTION_TYPES.SET_FONT_SIZE,
    payload: { fontSizeChange }
});

const setCustomer = (customer: IAppState['customer'], token: IAppState['token']) => ({
    type: ACTION_TYPES.SET_CUSTOMER,
    payload: { customer, token }
});

const setClipBoard = (value: IAppState['clipBoard']) => ({
    type: ACTION_TYPES.SET_CLIP_BOARD,
    payload: value
});

export { setTableOption, setDrawerState, setFontSize, setCustomer, setClipBoard, removeCustomer, resetApp };
