import Axios from 'axios';
import { configure } from 'axios-hooks';
import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { APP_TOKEN, SERVER_API_URL, SERVER_TIMEOUT } from '../../config/constants';
import { IRootState } from '../../store/_root.reducer';
import { setDrawerState } from '../../store/app.reducer';

interface AuthenticateProps extends StateProps, DispatchProps {}

export const Authenticate: FunctionComponent<AuthenticateProps> = props => {
    const { app } = props;
    const { token, customer } = app;

    useEffect(() => {
        const axios = Axios.create({
            baseURL: customer?.customer_type ? `${SERVER_API_URL}${customer?.customer_type}` : SERVER_API_URL,
            timeout: SERVER_TIMEOUT,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-sb-apptoken': APP_TOKEN,
                ...(token ? { 'x-sb-token': token } : {}),
                ...(customer?.customer_id ? { 'x-sb-customerid': customer.customer_id } : {})
            }
        });
        configure({ axios });
    }, [token, customer]);

    return null;
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    setDrawerState
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
