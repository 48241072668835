import {AppBar, IconButton, Toolbar, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import {flattenedRoutes} from '../../../App';
import {IRootState} from '../../store/_root.reducer';
import {CUSTOMER_TYPE, setDrawerState} from '../../store/app.reducer';
import Credits from '../credits/credits';
import Customer from './user/customer';

import './header.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        title: {
            flexGrow: 1
        }
    })
);

interface IHeaderProps extends StateProps, DispatchProps, RouteComponentProps {
}

export const Header: FunctionComponent<IHeaderProps> = props => {
    const {app, location} = props;
    const {drawer, customer} = app;
    const {t} = useTranslation();
    const classes = useStyles();

    const toggleDrawer = () => {
        const {isOpen} = drawer;

        props.setDrawerState({isOpen: !isOpen});
    };

    return (
        <section className={`Header ${classes.root}`}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer}
                        disabled={!customer}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {customer && t(`header.${customer?.customer_type}`)}
                        {flattenedRoutes.find(route => route.path === location.pathname)?.label}
                    </Typography>
                    {customer && (
                        <>
                            {
                                customer.customer_type !== CUSTOMER_TYPE.customer ? (
                                    <Typography variant="h6" className={classes.title}>
                                        {t(`header.balance`)}: <Credits credits={customer.credits}/>
                                    </Typography>
                                ):(
                                    <Typography variant="h6" className={classes.title}>
                                        {t(customer.isVIP ? `dashboard.vipSubscriber` : `dashboard.noVipSubscriber`)}
                                    </Typography>
                                )
                            }
                            <Customer/>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </section>
    );
};

const mapStateToProps = ({app}: IRootState) => ({
    app
});

const mapDispatchToProps = {
    setDrawerState
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
