import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { bindActionCreators } from 'redux';

import App from './App';
// import setupAxiosInterceptors from './app/config/axios-interceptor';

import store from './app/store/store';

import './i18n';

import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');
// const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
// setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
