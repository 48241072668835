import useAxios from 'axios-hooks';
import qs from 'querystring';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';

import UserData, { IUserDataErrors } from '../user-data/user-data';
import { Customer, setCustomer } from '../../store/app.reducer';
import { Loading } from '../loading/loading';
import RequestTeachersAccountTeaser from '../request-teachers-account/request-teachers-account-teaser';
import { IRootState } from '../../store/_root.reducer';

import './reset-password.scss';

interface IResetPasswordProps extends StateProps, DispatchProps, RouteComponentProps {}

export const ResetPassword: FunctionComponent<IResetPasswordProps> = props => {
    const { t } = useTranslation();
    const { app, history } = props;
    const { customer } = app;
    const [errors, setErrors] = useState<IUserDataErrors>({});

    const [{ loading, data: userData, error }, executeUpdateUserData] = useAxios(
        {
            url: 'update.php',
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        if (error) {
            setErrors({
                password: t(`userData.errors.api.resetPassword`)
            });
        }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleSubmit(customer: Partial<Customer>) {
        executeUpdateUserData({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                ...customer
            })
        });
    }

    const render = () => {
        switch (true) {
            case loading:
                return <Loading />;
            case !loading && !!userData:
                return (
                    <div className="success">
                        <h4>{t('userData.resetPassword.successTitle')}</h4>
                        <div>{t('userData.resetPassword.successInfo')}</div>
                        <Button variant="contained" onClick={() => history.push('/dashboard')}>
                            {t('userData.resetPassword.action.toDashboard')}
                        </Button>
                    </div>
                );
            default:
                return (
                    <>
                        <h4>{t('userData.resetPassword.title')}</h4>
                        {!!errors?.api && <div className="apiError">{errors.api}</div>}
                        <UserData customer={customer} context="resetPassword" apiErrors={errors} onSubmit={handleSubmit} />
                        <RequestTeachersAccountTeaser />
                    </>
                );
        }
    };

    return <section className={`ResetPassword`}>{render()}</section>;
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    setCustomer
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
