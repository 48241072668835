import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeadCell } from '../table/enhanced-table-head';
import { asCredits, asDateTime } from '../../utils/utils';
import Table from '../table/table';
import { ITransaction } from '../../models/models';

import './transactions.scss';

interface TransactionCell extends TableHeadCell {
    key: keyof ITransaction;
}

interface ITransactionsProps {
    transactions?: ITransaction[];
    title?: string;
}

export const Transactions: FunctionComponent<ITransactionsProps> = props => {
    const { t } = useTranslation();
    const { transactions, title } = props;

    const headCells: TransactionCell[] = [
        { key: 'credit_transaction_id', label: t(`transaction.credit_transaction_id`), options: { isNumeric: true } },
        { key: 'credits', label: t(`transaction.credits`), options: { format: asCredits, isNumeric: true } },
        { key: 'tr_date', label: t(`transaction.tr_date`), options: { format: asDateTime() } },
        { key: 'teacher_session_id', label: t(`transaction.teacher_session_id`), options: { isNumeric: true } },
        { key: 'customer_id', label: t(`transaction.customer_id`), options: { isNumeric: true } }
    ];

    return (
        <section className={`Transactions`}>
            {transactions && (
                <Table
                    title={title || t(`transactions.title`)}
                    noDataMessage={t(`transactions.noDataMessage`)}
                    headCells={headCells}
                    rows={transactions.map(transaction => ({ ...transaction, _id: `${transaction.credit_transaction_id}` }))}
                />
            )}
        </section>
    );
};
