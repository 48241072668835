import { AxiosError } from 'axios';
import React, { FunctionComponent } from 'react';

import { Loading } from '../loading/loading';
import { Error } from '../error/error';

interface IAxiosResultProps {
    loading: boolean;
    data: any;
    error?: AxiosError;
}

export const AxiosResult: FunctionComponent<IAxiosResultProps> = props => {
    const { loading, data, error, children } = props;

    return <>{[loading && <Loading key="loading" />, !loading && !!data && children, !!error && <Error {...{ error }} />]}</>;
};
