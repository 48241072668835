import { Button, ButtonGroup, Divider, Menu, MenuItem } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { IRootState } from '../../../store/_root.reducer';
import { FontSizeChange, setFontSize, setTableOption } from '../../../store/app.reducer';
import { tableRowsPerPageOptions } from '../../table/table';

import './settings.scss';

interface ISettingsProps extends StateProps, DispatchProps {}

export const Settings: FunctionComponent<ISettingsProps> = props => {
    const { app } = props;
    const { tableOptions, fontSize } = app;
    const { t } = useTranslation();
    const [optionsAnchor, setOptionsAnchor] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        document.documentElement.style.setProperty('--base-font-size', `${fontSize}px`);
    }, [fontSize]);

    const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOptionsAnchor(event.currentTarget);
    };

    const handleOptionsClose = () => {
        setOptionsAnchor(null);
    };

    const handleChangeDenseTables = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setTableOption({ isDense: event.target.checked });
    };

    const handleFontSizeChange = (fontSizeChange: FontSizeChange) => () => {
        props.setFontSize(fontSizeChange);
    };

    const handleTableRowsPerPageChange = (rowsPerPage: number) => () => {
        props.setTableOption({ rowsPerPage });
    };

    const options = [
        <FormControlLabel
            control={<Switch checked={tableOptions.isDense} onChange={handleChangeDenseTables} />}
            label={t('settings.denseTables')}
        />,
        [
            <div key="fontSize-1">{t('settings.fontSize')}:</div>,
            <ButtonGroup key="fontSize-2" fullWidth>
                <Button size="small" variant="outlined" onClick={handleFontSizeChange('-')}>
                    A -
                </Button>
                <Button size="small" variant="outlined" onClick={handleFontSizeChange('default')}>
                    A
                </Button>
                <Button size="small" variant="outlined" onClick={handleFontSizeChange('+')}>
                    A +
                </Button>
            </ButtonGroup>
        ],
        [
            <div key="rpp-1">{t('settings.tableRowsPerPage')}:</div>,
            <ButtonGroup key="rpp-2" fullWidth>
                {tableRowsPerPageOptions.map((rows, index) => (
                    <Button
                        key={`rpp-${index}`}
                        size="small"
                        variant={tableOptions.rowsPerPage === rows ? 'contained' : 'outlined'}
                        color={tableOptions.rowsPerPage === rows ? 'primary' : undefined}
                        disableElevation
                        onClick={handleTableRowsPerPageChange(rows)}
                    >
                        {rows}
                    </Button>
                ))}
            </ButtonGroup>
        ]
    ];

    return (
        <section className={`Settings`}>
            <Button
                className="open"
                aria-controls="bc-options"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleOptionsClick}
            >
                {t('settings.label')}
            </Button>
            <Menu id="simple-menu" anchorEl={optionsAnchor} keepMounted open={Boolean(optionsAnchor)} onClose={handleOptionsClose}>
                <MenuItem disabled />
                {options.map((option, index) => [
                    index !== 0 && <Divider />,
                    <div className="MuiMenuItem-root MuiListItem-gutters">{option}</div>
                ])}
                {/*<MenuItem onClick={handleOptionsClose}>Profile</MenuItem>*/}
            </Menu>
        </section>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    setTableOption,
    setFontSize
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
