import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { routes } from '../../../App';
import { IRootState } from '../../store/_root.reducer';
import { setDrawerState } from '../../store/app.reducer';
import Settings from './settings/settings';

import './drawer.scss';

const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: 'auto'
    }
});

export type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface TemporaryDrawerProps extends StateProps, DispatchProps, RouteComponentProps {}

export const TemporaryDrawer: FunctionComponent<TemporaryDrawerProps> = props => {
    const classes = useStyles();
    const { app, history } = props;
    const { drawer, customer } = app;
    const { anchor, isOpen } = drawer;

    const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        props.setDrawerState({ isOpen });
    };

    const goto = (path: string) => {
        history.push(path);
    };

    const list = () => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom'
            })}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            {routes.map((routesGroup, index) => (
                <React.Fragment key={`routesGroup-${index}`}>
                    <Divider />
                    <List>
                        {routesGroup.map(
                            route =>
                                (!route.inDrawerFor || (customer && route.inDrawerFor.includes(customer.customer_type))) && (
                                    <ListItem button key={route.path}>
                                        <ListItemText onClick={() => goto(route.path)}>{route.label}</ListItemText>
                                    </ListItem>
                                )
                        )}
                    </List>
                </React.Fragment>
            ))}
        </div>
    );

    return (
        <Drawer className="Drawer" anchor={anchor} open={isOpen} onClose={toggleDrawer(false)}>
            {list()}
            <Settings />
        </Drawer>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    setDrawerState
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TemporaryDrawer));
