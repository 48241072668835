import { Tooltip, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import './duration.scss';

export interface IDurationOptions {
    durationUnit?: 'm' | 's';
    format?: string;
}

interface IDurationProps {
    duration: number;
    options?: IDurationOptions;
}

export const Duration: FunctionComponent<IDurationProps> = props => {
    const { t } = useTranslation();
    const { duration, options } = props;

    const durationUnit = options?.durationUnit || 'm';
    const _format = options?.format || t('base.duration.format');

    return (
        <section className="Duration">
            <Tooltip title={`${duration}${t(`base.duration.unit.${durationUnit}`)}`}>
                <Typography className="duration">
                    {moment.utc(moment.duration(`PT${duration}${durationUnit.toUpperCase()}`).asMilliseconds()).format(_format)}
                </Typography>
            </Tooltip>
        </section>
    );
};

export default Duration;
