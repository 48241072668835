import useAxios from 'axios-hooks';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ITeacherSessionStudent } from '../../../models/teacher-models';
import { asDuration } from '../../../utils/utils';
import { AxiosResult } from '../../axios-result/axsio-result';
import { TableHeadCell } from '../../table/enhanced-table-head';
import Table from '../../table/table';

import './studentSessions.scss';
import { ISession } from '../../../models/models';

interface StudentSessionCell extends TableHeadCell {
    key: keyof ITeacherSessionStudent;
}

interface IStudentSessionsProps {
    teacherSessionId: ISession['teacher_session_id'];
}

export const StudentSessions: FunctionComponent<IStudentSessionsProps> = props => {
    const { t } = useTranslation();
    const { teacherSessionId } = props;

    const [{ loading, data, error }] = useAxios({
        url: `session.php?teacher_session_id=${teacherSessionId}`,
        method: 'POST'
    });

    const studentsHeadCells: StudentSessionCell[] = [
        { key: 'student_id', label: t(`student.id`) },
        { key: 'duration', label: t(`teacher.session.duration`), options: { format: asDuration(), isNumeric: true } },
        { key: 'student_uuid', label: t(`student.uuid`) }
    ];

    return (
        <section className={`StudentSessions`}>
            <AxiosResult {...{ loading, data, error }}>
                <Table
                    title={t('student.sessions.title')}
                    headCells={studentsHeadCells}
                    rows={(data || []).map((student: ITeacherSessionStudent) => ({
                        ...student,
                        _id: `${teacherSessionId}-${student.student_id}`
                    }))}
                    noDataMessage={t('student.sessions.noStudents', { session: teacherSessionId })}
                />
            </AxiosResult>
        </section>
    );
};
