import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ITeacher } from '../../../models/teacher-models';
import Table from '../../../shared/table/table';
import { TableHeadCell } from '../../../shared/table/enhanced-table-head';

import './teachers.scss';

interface TeacherCell extends TableHeadCell {
    key: keyof ITeacher;
}

interface ITeachersProps {
    teachers?: ITeacher[];
}

export const Teachers: FunctionComponent<ITeachersProps> = props => {
    const { t } = useTranslation();
    const { teachers } = props;

    const headCells: TeacherCell[] = [
        { key: 'teacherid', label: t(`club.teacher.teacherid`) },
        { key: 'lastname', label: t(`club.teacher.lastname`) },
        { key: 'firstname', label: t(`club.teacher.firstname`) },
        { key: 'email', label: t(`club.teacher.email`) },
        { key: 'credits', label: t(`club.teacher.credits`) },
        {
            key: 'teacherid',
            label: t(`club.teacher.details`),
            options: {
                format: (teacherid: ITeacher['teacherid']) => (
                    <Link to={`/dashboard?teacherId=${teacherid}`}>{t('club.teacher.details')}</Link>
                )
            }
        }
    ];

    return (
        <section className={`Teachers`}>
            {teachers && (
                <Table
                    title={t(`club.teachers.title`)}
                    noDataMessage={t(`club.teachers.noDataMessage`)}
                    headCells={headCells}
                    rows={teachers.map(teacher => ({ ...teacher, _id: `${teacher.teacherid}` }))}
                />
            )}
        </section>
    );
};
