import React, { FunctionComponent } from 'react';

import './loading.scss';

interface ILoadingProps {}

export const Loading: FunctionComponent<ILoadingProps> = props => {
    return (
        <section className={`Loading`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </section>
    );
};
