import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import qs from 'querystring';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';

import UserData, { IUserDataErrors } from '../user-data/user-data';
import { Customer } from '../../store/app.reducer';
import { Loading } from '../loading/loading';
import { Translate } from '../../utils/translate';
import RequestTeachersAccountTeaser from '../request-teachers-account/request-teachers-account-teaser';

import './register.scss';

interface IRegisterProps extends RouteComponentProps {}

const Register: FunctionComponent<IRegisterProps> = props => {
    const { t } = useTranslation();
    const { history } = props;
    const [errors, setErrors] = useState<IUserDataErrors>({});
    const [newCustomer, setNewCustomer] = useState<Partial<Customer>>({});

    const [{ loading, data: userData, error }, executeRegister] = useAxios(
        {
            url: 'register.php',
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        if (error) {
            const errorCode = error?.response?.data?.error;
            let _errors: IUserDataErrors = {};

            const interpolate = {
                email: newCustomer.email,
                nickName: newCustomer.nickname
            };

            if (errorCode === 'duplicate email or nickname') {
                _errors = {
                    ..._errors,
                    email: t(`userData.errors.api.${errorCode}`, interpolate),
                    nickName: t(`userData.errors.api.${errorCode}`, interpolate)
                };
            }
            setErrors(_errors);
        }
    }, [error, newCustomer, t]);

    function handleUserRegister(customer: Partial<Customer>) {
        setNewCustomer(customer);
        executeRegister({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(customer)
        });
    }

    const renderContent = () => {
        switch (true) {
            case loading:
                return <Loading />;
            case !!userData:
                return (
                    <>
                        <div className="success">
                            <h5>
                                <Translate contentKey="register.success.headline" />
                            </h5>
                            <p>
                                <Translate contentKey="register.success.text" />
                            </p>
                            <Button variant="contained" color="primary" onClick={() => history.push('/login')}>
                                <Translate contentKey="register.success.toLogin" />
                            </Button>
                        </div>
                        <RequestTeachersAccountTeaser />
                    </>
                );
            default:
                return (
                    <>
                        <UserData apiErrors={errors} context="register" onSubmit={handleUserRegister} />
                        <div className="toLogin">
                            <p>
                                <Translate contentKey="register.toLogin.text" />
                            </p>
                            <Button variant="contained" type="button" onClick={() => history.push('/login')}>
                                {t('register.toLogin.action')}
                            </Button>
                        </div>
                    </>
                );
        }
    };

    return <section className="Register">{renderContent()}</section>;
};

export default withRouter(Register);
