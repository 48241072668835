import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

import {TableHeadCell} from '../table/enhanced-table-head';
import {asCredits, asDateTime, asLink} from '../../utils/utils';
import Table from '../table/table';
import {ICustomerOrder, IOrder} from '../../models/models';

import './orders.scss';

interface OrderCell extends TableHeadCell {
    key: keyof IOrder | keyof ICustomerOrder;
}

interface IOrdersProps {
    orders?: IOrder[];
    customerOrders?: ICustomerOrder[];
    title?: string;
}

export const Orders: FunctionComponent<IOrdersProps> = props => {
    const {t} = useTranslation();
    const {orders, customerOrders, title} = props;

    const headCells: OrderCell[] = orders ? [
        {key: 'credit_transaction_id', label: t(`order.credit_transaction_id`), options: {isNumeric: true}},
        {key: 'credits', label: t(`order.credits`), options: {format: asCredits, isNumeric: true}},
        {key: 'tr_date', label: t(`order.tr_date`), options: {format: asDateTime()}},
        {key: 'order_id', label: t(`order.order_id`)},
        {key: 'order_url', label: t(`order.order_url`), options: {format: asLink(t(`order.toOrder`))}}
    ] : customerOrders ? [
        {key: 'order_id', label: t(`order.order_id`)},
        {key: 'total', label: t(`order.total`)},
        {key: 'tr_date', label: t(`order.tr_date`), options: {format: asDateTime()}},
        {key: 'order_url', label: t(`order.order_url`), options: {format: asLink(t(`order.toOrder`))}}
    ] : [];

    return (
        <section className={`Orders`}>
            {orders && (
                <Table
                    title={title || t(`orders.title`)}
                    noDataMessage={t(`orders.noDataMessage`)}
                    headCells={headCells}
                    rows={orders.map(order => ({...order, _id: `${order.credit_transaction_id}`}))}
                />
            )}
            {customerOrders && (
                <Table
                    title={title || t(`orders.title`)}
                    noDataMessage={t(`orders.noDataMessage`)}
                    headCells={headCells}
                    rows={customerOrders.map(order => ({
                        ...order,
                        total: `${order.currency_code} ${Number(order.total)}`,
                        _id: `${order.order_id}`,
                    }))}
                />
            )}
        </section>
    );
};

export default Orders;
