import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { IRootState } from '../../store/_root.reducer';
import { CUSTOMER_TYPE, removeCustomer } from '../../store/app.reducer';

interface IOwnProps extends RouteProps {
    hasAnyAuthorities?: string[];
    redirect?: string;
    customProps?: any;
    authorizedCustomers?: CUSTOMER_TYPE[];
}

export interface IPrivateRouteProps extends IOwnProps, StateProps, DispatchProps {}

export const PrivateRoute = ({
    component: Component,
    authorizedCustomers,
    redirect,
    customProps,
    removeCustomer: storeRemoveCustomer,
    app,
    ...rest
}: IPrivateRouteProps) => {
    const reDirectTo = (redirectTo: string, location: RouteProps['location']) => {
        if (location?.pathname !== redirectTo) {
            storeRemoveCustomer();
            return (
                <Redirect
                    to={{
                        pathname: redirectTo,
                        search: location?.search,
                        state: { from: location }
                    }}
                />
            );
        } else {
            // @ts-ignore
            return <Component {...customProps} />;
        }
    };

    const renderRedirect = (props: RouteProps) => {
        switch (true) {
            case !authorizedCustomers:
            case !!(app.customer?.customer_type && authorizedCustomers?.indexOf(app.customer.customer_type) !== -1): {
                // @ts-ignore
                return <Component {...customProps} />;
            }
            case !app.customer?.customer_type: {
                return reDirectTo(redirect || '/login', props.location);
            }
            default: {
                return reDirectTo(redirect || '/dashboard', props.location);
            }
        }
    };

    if (!Component) {
        throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
    }

    return <Route {...rest} render={renderRedirect} />;
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    removeCustomer
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
