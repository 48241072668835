import { AxiosError } from 'axios';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface IErrorProps {
    error: AxiosError;
}

export const Error: FunctionComponent<IErrorProps> = props => {
    const { t } = useTranslation();
    const { error } = props;

    const translatedErrorCodes = [401, 403];

    const useErrorCode =
        error.response?.status && translatedErrorCodes.indexOf(error.response.status) !== -1 ? error.response?.status : 'unknown';

    return <section className="Error">{t(`error.${useErrorCode}`)}</section>;
};
