import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import useAxios from 'axios-hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import ClubDashboard from '../../club/dashboard/club-dashboard';
import { IRootState } from '../../store/_root.reducer';
import { CUSTOMER_TYPE, resetApp } from '../../store/app.reducer';
import TeacherDashboard from '../../teacher/dashboard/teacher-dashboard';
import { AxiosResult } from '../axios-result/axsio-result';
import OrderCredits from '../order-credits/order-credits';

import './dashboard.scss';
import CustomerDashboard from '../../customer/dashboard/customer-dashboard';
import ManageVip from '../manage-vip/manage-vip';
import { propagateUser } from '../../utils/utils';

interface IDashboardProps extends StateProps, DispatchProps, RouteComponentProps {
}

export const Dashboard: FunctionComponent<IDashboardProps> = props => {
    const { customer_type, isVip } = props;
    const { t } = useTranslation();
    const [{ loading, data, error }, fetchDashboard] = useAxios(
        {
            url: `index.php`,
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        fetchDashboard();
    }, []);

    useEffect(() => {
        if (data?.error === 'unauthorized') {
            propagateUser();
            props.resetApp();
        }
    }, [data]);

    if (!customer_type) {
        return <Redirect to="/login" />;
    }

    return (
        <section className={`Dashboard`}>
            <div className="actions">
                {customer_type === CUSTOMER_TYPE.customer && <ManageVip isVip={Boolean(isVip)} />}
                {customer_type !== CUSTOMER_TYPE.customer && <OrderCredits />}
                <Button
                    variant="contained"
                    color="primary"
                    className="refresh"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchDashboard()}
                >
                    {t('teacher.dashboard.refresh')}
                </Button>
            </div>
            <AxiosResult {...{ loading, data, error }}>
                {customer_type && [
                    customer_type === CUSTOMER_TYPE.teacher && <TeacherDashboard key="TeacherDashboard" {...{ data }} />,
                    customer_type === CUSTOMER_TYPE.club && <ClubDashboard key="ClubDashboard" {...{ data }} />,
                    customer_type === CUSTOMER_TYPE.customer && <CustomerDashboard key="CustomerDashboard" {...{ data }} />
                ]}
            </AxiosResult>
        </section>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({
    customer_type: app.customer?.customer_type,
    isVip: app.customer?.isVIP,
});

const mapDispatchToProps = {
    resetApp
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
