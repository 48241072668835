import { Button, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer } from '../../store/app.reducer';

import './user-data.scss';

export interface IUserDataErrors {
    [key: string]: string;
}

export interface IUserData extends Partial<Customer> {
    password: string;
    repeatPassword: string;
    nickname_email: string;
}

type UserdataInputs = keyof IUserData;

const nicknameMaxLength = 15;

interface IUserDataProps {
    context: 'register' | 'update' | 'forgotPassword' | 'resetPassword';
    customer?: Customer;
    apiErrors?: IUserDataErrors;
    onSubmit: (customer: Partial<Customer>) => void;
}

export const UserData: FunctionComponent<IUserDataProps> = props => {
    const { t } = useTranslation();
    const { context: _context, customer, onSubmit, apiErrors } = props;
    const { email, firstname, lastname, nickname } = customer || {};
    const context = _context || 'update';

    const [userData, setUserData] = useState<IUserData>({
        email: email || '',
        firstname: firstname || '',
        lastname: lastname || '',
        nickname: nickname || '',
        password: '',
        repeatPassword: '',
        nickname_email: ''
    });
    const [errors, setErrors] = useState<IUserDataErrors | undefined>(apiErrors);

    useEffect(() => {
        if (customer) {
            setUserData({
                email: customer.email,
                firstname: customer.firstname,
                lastname: customer.lastname,
                nickname: customer.nickname,
                password: '',
                repeatPassword: '',
                nickname_email: ''
            });
        }
    }, [customer]);

    useEffect(() => {
        setErrors(apiErrors);
    }, [apiErrors]);

    const handleSubmitUserData = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        validateUserData();
    };

    const getInputs = (): UserdataInputs[] => {
        const inputs: { [key: string]: UserdataInputs[] } = {
            forgotPassword: ['nickname_email'],
            register: ['nickname', 'email', 'firstname', 'lastname', 'password', 'repeatPassword'],
            resetPassword: ['password', 'repeatPassword'],
            update: ['nickname', 'email', 'firstname', 'lastname', 'password', 'repeatPassword']
        };

        return inputs[context];
    };

    const getRequiredInputs = (): (UserdataInputs | string)[] => {
        const requiredInputs: { [key: string]: (UserdataInputs | string)[] } = {
            forgotPassword: ['nickname_email'],
            register: ['nickname', 'firstname', 'lastname', 'email', 'password', 'repeatPassword'],
            resetPassword: ['password', ...(userData.password ? ['repeatPassword'] : [])],
            update: ['nickname', 'firstname', 'lastname', 'email', ...(userData.password ? ['repeatPassword'] : [])]
        };

        return requiredInputs[context];
    };

    const validateRequired = (): IUserDataErrors => {
        const requiredInputs = getRequiredInputs();
        return requiredInputs.reduce((prev, key) => {
            const value: any = userData[key as keyof IUserData];
            return {
                ...prev,
                ...(value ? {} : { [key]: t(`userData.errors.${key}.required`) })
            };
        }, {});
    };

    const validateNickname = (nickname?: IUserData['nickname']): IUserDataErrors => {
        const _nickname = `${nickname || userData.nickname || ''}`;
        if (_nickname.length > nicknameMaxLength) {
            return { nickname: t(`userData.errors.nickname.size`, { maxLength: nicknameMaxLength }) };
        }
        return {};
    };

    const validateEmail = (email?: IUserData['email']): IUserDataErrors => {
        const _email = email || userData.email;

        const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (_email && !emailRegExp.test(String(_email).toLowerCase())) {
            return { email: t(`userData.errors.email.invalid`) };
        }
        return {};
    };

    const validatePassword = (): IUserDataErrors => {
        const { password, repeatPassword } = userData;
        if (password && repeatPassword && password !== repeatPassword) {
            return { repeatPassword: t(`userData.errors.repeatPassword.misMatch`) };
        }
        return {};
    };

    const validateUserData = () => {
        let _errors: IUserDataErrors;

        if (context === 'forgotPassword') {
            _errors = {
                ...validateRequired()
            };

            if (!Object.keys(_errors).length) {
                _errors = {
                    ...validateNickname(userData.nickname_email),
                    ...validateEmail(userData.nickname_email)
                };
                if (Object.keys(_errors).length < 2) {
                    _errors = {};
                } else {
                    _errors = {
                        nickname_email: t(`userData.errors.nickname_email`, { maxLength: nicknameMaxLength + 1 })
                    };
                }
            }
        } else {
            _errors = {
                ...validateNickname(),
                ...validateEmail(),
                ...validatePassword(),
                ...validateRequired()
            };
        }
        if (!Object.keys(_errors).length) {
            onSubmit(userData);
        } else {
            setErrors(_errors);
        }
    };

    const handleChange = (key: keyof IUserData) => (e: ChangeEvent<HTMLInputElement>) => {
        if (errors) {
            const _errors = { ...errors };
            delete _errors[key];
            setErrors(_errors);
        }
        setUserData({
            ...userData,
            [key]: e.target.value
        });
    };

    const renderInputs = () => {
        const inputs = getInputs();
        return inputs.map(inputKey => {
            const type = inputKey === 'password' || inputKey === 'repeatPassword' ? 'password' : 'text';
            const required = getRequiredInputs().hasOwnProperty(inputKey);
            const error = !!errors && !!errors[inputKey];
            const helperText = errors ? errors[inputKey] : undefined;
            return (
                <div key={inputKey}>
                    <TextField
                        type={type}
                        required={required}
                        error={error}
                        label={t(`userData.${context}.${inputKey}`)}
                        value={userData[inputKey]}
                        onChange={handleChange(inputKey)}
                        helperText={helperText}
                    />
                </div>
            );
        });
    };

    return (
        <section className={`UserData ${context}`}>
            {!!errors?.api && <div className="apiError">{errors.api}</div>}
            <form noValidate autoComplete="off" key="UserDataForm" onSubmit={handleSubmitUserData}>
                {renderInputs()}
                <Button variant="contained" color="primary" type="submit">
                    {t(`userData.${context}.action.submit`)}
                </Button>
            </form>
        </section>
    );
};

export default UserData;
