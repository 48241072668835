import { Button, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IClubDashboard } from '../../models/club-models';
import { Sessions } from '../../shared/sessions/sessions';
import { Transactions } from '../../shared/transactions/transactions';
import { Translate } from '../../utils/translate';
import { ITeacher } from '../../models/teacher-models';
import { useTranslation } from 'react-i18next';

import './club-teacher-dashboard.scss';

interface IClubTeacherDashboardProps extends RouteComponentProps {
    data: IClubDashboard;
    teacher: ITeacher;
}

export const ClubTeacherDashboard: FunctionComponent<IClubTeacherDashboardProps> = props => {
    const { t } = useTranslation();
    const { data, teacher, history } = props;
    const { firstname, lastname } = teacher;

    const sessions = (data.teacher_sessions || []).filter(session => session.teacherid === teacher.teacherid);
    const transactions = (data.transactions || []).filter(transaction => transaction.teacherid === teacher.teacherid);
    const teachersName = [firstname, lastname].join(' ');

    return (
        <section className={`ClubTeacherDashboard`}>
            <Button className="goBack" variant="contained" onClick={() => history.push('/dashboard')}>
                Go Back
            </Button>
            <Typography variant="h5">
                <Translate contentKey="club.teacher.dashboard.title" interpolate={{ teacher: teachersName }} />
            </Typography>
            <Sessions sessions={sessions} title={t('club.teacher.dashboard.sessions.title', { teacher: teachersName })} />
            <Transactions transactions={transactions} title={t('club.teacher.dashboard.transactions.title', { teacher: teachersName })} />
        </section>
    );
};
export default withRouter(ClubTeacherDashboard);
