import React, { FunctionComponent } from 'react';
import useAxios from 'axios-hooks';
import qs from 'querystring';

import UserData, { IUserData } from '../user-data/user-data';
import { Loading } from '../loading/loading';
import { Translate } from '../../utils/translate';

import './forgot-password.scss';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IforgotPasswordProps extends RouteComponentProps {
    requested: 'email' | 'nickname';
}

const ForgotPassword: FunctionComponent<IforgotPasswordProps> = props => {
    const { t } = useTranslation();
    const { history } = props;

    const [{ loading, data, error }, executeForgotPassword] = useAxios(
        {
            url: 'forgot.php',
            method: 'POST'
        },
        { manual: true }
    );

    const handleSubmit = (userData: Partial<IUserData>) => {
        executeForgotPassword({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                identifier: userData.nickname_email,
                ...(window.location.hostname === 'localhost' ? { local: true } : {})
            })
        });
    };

    const getApiErrors = () => {
        const errorMessages: { [key: string]: UseTranslationResponse } = {
            nouser: t('userData.forgotPassword.error.nouser'),
            unknown: t('userData.forgotPassword.error.unknown')
        };

        if (error) {
            const _error = error.response?.data.error;
            return errorMessages.hasOwnProperty(_error) ? { email: `${errorMessages[_error]}` } : { email: `${errorMessages.unknown}` };
        } else {
            return undefined;
        }
    };

    const renderContent = () => {
        switch (true) {
            case !!data:
                return (
                    <div className="success">
                        <Translate contentKey={`userData.forgotPassword.success`} />
                        <div className="toLogin">
                            <Button variant="contained" type="button" onClick={() => history.push('/login')}>
                                {t('userData.forgotPassword.toLogin')}
                            </Button>
                        </div>
                    </div>
                );
            default:
                return (
                    <>
                        <div className="intro">
                            <Translate contentKey="userData.forgotPassword.intro" />
                        </div>
                        {loading && <Loading />}
                        <UserData context="forgotPassword" apiErrors={getApiErrors()} onSubmit={handleSubmit} />
                    </>
                );
        }
    };

    return <section className="forgotPassword">{renderContent()}</section>;
};

export default withRouter(ForgotPassword);
