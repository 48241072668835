import { combineReducers } from 'redux';

import example, { IExampleState } from './example.reducer';
import app, { IAppState } from './app.reducer';

export interface IRootState {
    readonly example: IExampleState;
    readonly app: IAppState;
}

const _rootReducer = combineReducers<IRootState>({
    example,
    app
});

const rootReducer = (state: any, action: any) => {
    return _rootReducer(state, action);
};

export default rootReducer;
