import React, { FunctionComponent } from 'react';
import moment, { MomentInput } from 'moment';

import { useTranslation } from 'react-i18next';

import './date-time.scss';

interface IDateTimeProps {
    date: MomentInput;
    format?: {
        date: string;
        time?: string;
    };
    noTime?: boolean;
}

export const DateTime: FunctionComponent<IDateTimeProps> = props => {
    const { t } = useTranslation();
    const { date, format, noTime } = props;

    return (
        <section className="DateTime">
            <span className="date">{moment(date).format(format?.date || t('base.date'))}</span>
            {!noTime && <span className="time">{moment(date).format(format?.time || t('base.time'))}</span>}
        </section>
    );
};

export default DateTime;
