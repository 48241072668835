import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Translate } from '../../utils/translate';
import { IRootState } from '../../store/_root.reducer';
import { CUSTOMER_TYPE, setDrawerState } from '../../store/app.reducer';

import './request-teachers-account-teaser.scss';

interface IRequestTeachersAccountTeaserProps extends StateProps, DispatchProps, RouteComponentProps {}

const RequestTeachersAccountTeaser: FunctionComponent<IRequestTeachersAccountTeaserProps> = props => {
    const { app, history } = props;
    const { customer } = app;

    return customer && [CUSTOMER_TYPE.customer].includes(customer?.customer_type) ? (
        <section className={`RequestTeachersAccountTeaser`}>
            <Button variant="contained" onClick={() => history.push('/request-teachers-account')}>
                <Translate contentKey="requestTeachersAccountTeaser.action" />
            </Button>
        </section>
    ) : null;
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    setDrawerState
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RequestTeachersAccountTeaser));
