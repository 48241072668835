import { Box, Collapse, IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell, { TableCellProps } from '@material-ui/core/TableCell';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTablePagination from '@material-ui/core/TablePagination';
import MuiTableRow, { TableRowProps } from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IRootState } from '../../store/_root.reducer';
import { setTableOption } from '../../store/app.reducer';
import { getComparator, Order, stableSort } from '../../utils/utils';
import { EnhancedTableHead, TableHeadCell } from './enhanced-table-head';
import { EnhancedTableToolbar } from './enhanced-table-toolbar';

import './table.scss';

export const tableRowsPerPageOptions = [1, 5, 10, 25];

interface ITableCellProps {
    collapsables?: Array<{ key: string; element: JSX.Element }>;
}

export interface ITableCell {
    [key: string]: any;

    _id: string;

    _options?: TableCellProps & ITableCellProps;
}

export const useTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2)
        },
        table: {
            minWidth: 750
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1
        }
    })
);

export interface ITableProps extends StateProps, DispatchProps, RouteComponentProps {
    headCells: TableHeadCell[];
    rows: ITableCell[];

    title?: string;
    isSelectable?: boolean;
    isFilterable?: boolean;
    withEmptyRows?: boolean;
    noDataMessage?: string;
}

export const Table: FunctionComponent<ITableProps> = props => {
    const { title, headCells, rows, location, isSelectable, withEmptyRows, app, noDataMessage } = props;
    const { tableOptions } = app;
    const { isDense, rowsPerPage } = tableOptions;
    const classes = useTableStyles();
    const { t } = useTranslation();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('calories');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [id, setId] = useState<string>('');
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        setId(`${title}-${location.pathname}`);
    }, [title, location.pathname]);

    useEffect(() => {
        setPage(0);
    }, [rowsPerPage]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleRowClick = (event: React.MouseEvent<unknown>, id: ITableCell['_id']) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleCollapse = (headCellKey: TableHeadCell['key'], index: number) => () => {
        const newOpen = {
            ...open,
            [`${headCellKey}-${index}`]: !open[`${headCellKey}-${index}`]
        };
        setOpen(newOpen);
    };

    const isSelected = (id: ITableCell['_id']) => selected.indexOf(id) !== -1;

    const emptyRows = withEmptyRows ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage) : 0;

    return (
        <div className={`${classes.root} Table`} id={id}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} tableProps={props} />
                {!!rows && !!rows.length ? (
                    <>
                        <MuiTableContainer>
                            <MuiTable
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={isDense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    tableProps={props}
                                />
                                <MuiTableBody>
                                    {stableSort(rows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, rowIndex) => {
                                            const isItemSelected = isSelected(row.id as string);
                                            const labelId = `enhanced-table-checkbox-${rowIndex}`;

                                            const tableRowProps: TableRowProps = {
                                                hover: true,
                                                role: 'checkbox',
                                                'aria-checked': isItemSelected,
                                                tabIndex: -1,
                                                selected: isItemSelected,
                                                ...(isSelectable ? { onClick: event => handleRowClick(event, row.id as string) } : {})
                                            };

                                            return (
                                                <React.Fragment key={row._id}>
                                                    <MuiTableRow {...tableRowProps}>
                                                        {isSelectable && (
                                                            <MuiTableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </MuiTableCell>
                                                        )}
                                                        {headCells.map((headCell, index) => {
                                                            const rowCell = rows.find(row => row[headCell.key]);
                                                            const tableCellProps: TableCellProps = {
                                                                align:
                                                                    rowCell?._options?.align || headCell.options?.isNumeric
                                                                        ? 'right'
                                                                        : 'left'
                                                            };
                                                            return (
                                                                <MuiTableCell key={`headCell-${index}`} {...tableCellProps}>
                                                                    {headCell.options?.format
                                                                        ? headCell.options.format(row[headCell.key])
                                                                        : row[headCell.key] || '-'}
                                                                    {!!((row as ITableCell)._options?.collapsables || []).find(
                                                                        collapsable => collapsable.key === headCell.key
                                                                    ) && (
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            onClick={handleCollapse(headCell.key, rowIndex)}
                                                                        >
                                                                            {open[`${headCell.key}-${rowIndex}`] ? (
                                                                                <KeyboardArrowUpIcon />
                                                                            ) : (
                                                                                <KeyboardArrowDownIcon />
                                                                            )}
                                                                        </IconButton>
                                                                    )}
                                                                </MuiTableCell>
                                                            );
                                                        })}
                                                    </MuiTableRow>
                                                    {!!(row as ITableCell)._options?.collapsables?.length && (
                                                        <MuiTableRow>
                                                            <MuiTableCell
                                                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                                                colSpan={headCells.length + (isSelectable ? 1 : 0)}
                                                            >
                                                                {((row as ITableCell)._options?.collapsables || []).map(collapsable => (
                                                                    <Collapse
                                                                        key={`${collapsable.key}-${rowIndex}`}
                                                                        in={open[`${collapsable.key}-${rowIndex}`]}
                                                                        timeout="auto"
                                                                        unmountOnExit
                                                                    >
                                                                        <Box margin={1}>{collapsable.element}</Box>
                                                                    </Collapse>
                                                                ))}
                                                            </MuiTableCell>
                                                        </MuiTableRow>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <MuiTableRow style={{ height: (isDense ? 33 : 53) * emptyRows }}>
                                            <MuiTableCell colSpan={headCells.length} />
                                        </MuiTableRow>
                                    )}
                                </MuiTableBody>
                            </MuiTable>
                        </MuiTableContainer>
                        {rows.length > rowsPerPage && (
                            <MuiTablePagination
                                rowsPerPageOptions={tableRowsPerPageOptions}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                            />
                        )}
                    </>
                ) : (
                    <div className="noDataMessage">{noDataMessage || t('table.noDataMessage')}</div>
                )}
            </Paper>
        </div>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({ app });

const mapDispatchToProps = {
    setTableOption
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Table));
