import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import moment, { MomentInput } from 'moment';
import Credits from '../shared/credits/credits';
import DateTime from '../shared/date-time/date-time';
import Duration, { IDurationOptions } from '../shared/duration/duration';
import { Customer } from '../store/app.reducer';

export const storeString = (setter: Dispatch<SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
};

export const storeBoolean = (setter: Dispatch<SetStateAction<boolean>>) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.checked);
};

export type Order = 'asc' | 'desc';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export const formatDate = (format = 'MM-DD-YYYY') => (content: MomentInput) => {
    return moment(content).format(format);
};

export const asDate = (format?: { date: string }) => (date: MomentInput) => {
    return <DateTime {...{ date, format, noTime: true }} />;
};

export const asDateTime = (format?: { date: string; time: string }) => (date: MomentInput) => {
    return <DateTime {...{ date, format }} />;
};

export const asLink = (label?: string) => (url: string): JSX.Element => {
    return (
        <a href={url} target={label}>
            {label}
        </a>
    );
};

export const asCredits = (credits: number) => {
    return <Credits {...{ credits }} />;
};

export const asDuration = (options?: IDurationOptions) => (duration: number) => {
    return <Duration {...{ duration, options }} />;
};

export const getSearchParams = (search: string): { [key: string]: string | number } => {
    const searchParams = new URLSearchParams(search);

    let urlParams = {};
    // @ts-ignore
    for (let pair of searchParams) {
        urlParams = {
            ...urlParams,
            [pair[0]]: pair[1]
        };
    }
    return urlParams;
};

export const propagateUser = (customer?: Customer) => {
    try {
        //@ts-ignore
        window.external.Customer(JSON.stringify(customer || {}));
    } catch (e) {}
};

export const copyToClipboard = (input: HTMLInputElement) => {
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand('copy');
    return input.value;
};
