import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import Authenticate from './app/shared/authenticate/_authenticate';
import PrivateRoute from './app/shared/authenticate/private-route';
import Dashboard from './app/shared/dashboard/dashboard';
import TemporaryDrawer from './app/shared/drawer/drawer';
import Header from './app/shared/header/header';
import Login from './app/shared/login/login';
import { CUSTOMER_TYPE } from './app/store/app.reducer';
import UserUpdate from './app/user-update/user-update';
import { Translate } from './app/utils/translate';
import Register from './app/shared/register/register';
import ForgotPassword from './app/shared/forgot-password/forgot-password';
import RequestTeachersAccount from './app/shared/request-teachers-account/request-teachers-account';
import ResetPassword from './app/shared/reset-password/reset-password';

import './App.scss';

interface IRoute {
    path: string;
    component: any;
    label: React.ReactElement;
    inDrawerFor?: CUSTOMER_TYPE[];
    authorized?: CUSTOMER_TYPE[];
    customProps?: any;
}

export const routes: IRoute[][] = [
    [
        {
            path: '/login',
            component: Login,
            label: <Translate contentKey="route.login" />,
            inDrawerFor: []
        },
        {
            path: '/register',
            component: Register,
            label: <Translate contentKey="route.register" />,
            inDrawerFor: []
        },
        {
            path: '/forgot-password',
            component: ForgotPassword,
            label: <Translate contentKey="route.forgotPassword" />,
            customProps: { requested: 'password' },
            inDrawerFor: []
        },
        {
            path: '/dashboard',
            component: Dashboard,
            label: <Translate contentKey={`route.dashboard`} />,
            authorized: [CUSTOMER_TYPE.teacher, CUSTOMER_TYPE.club, CUSTOMER_TYPE.customer]
        },
        {
            path: '/user-update',
            component: UserUpdate,
            label: <Translate contentKey="route.userUpdate" />,
            authorized: [CUSTOMER_TYPE.teacher, CUSTOMER_TYPE.club, CUSTOMER_TYPE.customer]
        },
        {
            path: '/reset-password',
            component: ResetPassword,
            label: <Translate contentKey="route.resetPassword" />,
            inDrawerFor: [CUSTOMER_TYPE.teacher, CUSTOMER_TYPE.club, CUSTOMER_TYPE.customer],
            authorized: [CUSTOMER_TYPE.teacher, CUSTOMER_TYPE.club, CUSTOMER_TYPE.customer]
        },
        {
            path: '/request-teachers-account',
            component: RequestTeachersAccount,
            label: <Translate contentKey="route.requestTeachersAccount" />,
            authorized: [CUSTOMER_TYPE.teacher, CUSTOMER_TYPE.club, CUSTOMER_TYPE.customer],
            inDrawerFor: [CUSTOMER_TYPE.customer]
        }
    ]
];

export const flattenedRoutes = routes.reduce((prev, current) => [...prev, ...current], []);

function App() {
    return (
        <Suspense fallback={null}>
            <section className={`App`}>
                <Authenticate />
                <BrowserRouter>
                    <Header />
                    <TemporaryDrawer />
                    <Switch>
                        {routes.map(routesGroup =>
                            routesGroup.map(route => (
                                <PrivateRoute
                                    path={route.path}
                                    component={route.component}
                                    authorizedCustomers={route.authorized}
                                    customProps={route.customProps}
                                />
                            ))
                        )}
                        <Redirect to="/login" />
                    </Switch>
                </BrowserRouter>
            </section>
        </Suspense>
    );
}

export default App;
