import React, {FunctionComponent} from 'react';

import {ITeacherDashboard} from '../../models/teacher-models';
import {Orders} from '../../shared/orders/orders';
import {Sessions} from '../../shared/sessions/sessions';
import {Transactions} from '../../shared/transactions/transactions';
import {DuplicateGames} from '../../shared/duplicate-games/duplicate-games';

import './teacher-dashboard.scss';

interface ITeacherDashboardProps {
    data: ITeacherDashboard;
}

export const TeacherDashboard: FunctionComponent<ITeacherDashboardProps> = props => {
    const {data} = props;
    return data ? (
        <section className={`TeacherDashboard`}>
            <Sessions sessions={data.teacher_sessions}/>
            <Transactions transactions={data.transactions}/>
            <Orders orders={data.orders}/>
            <DuplicateGames duplicateGames={data.games}/>
        </section>
    ) : null;
};

export default TeacherDashboard;
