import { Button, TextField } from '@material-ui/core';
import useAxios from 'axios-hooks';
import qs from 'querystring';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';

import { IRootState } from '../../store/_root.reducer';
import { setCustomer } from '../../store/app.reducer';
import { getSearchParams, propagateUser, storeString } from '../../utils/utils';
import { Loading } from '../loading/loading';

import './login.scss';
import { Translate } from '../../utils/translate';

interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps {
}

export const Login: FunctionComponent<ILoginProps> = props => {
    const { t } = useTranslation();
    const { history, setCustomer: propsSetCustomer, location } = props;
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [storedUser, setStoredUser] = useLocalStorage('bc-user', undefined);
    const [storedToken, setStoredToken] = useLocalStorage('bc-token', undefined);
    const { token, resettoken } = getSearchParams(location.search);

    const [{ data: userData, loading }, executeLogin] = useAxios(
        {
            url: 'auth.php',
            method: 'POST'
        },
        { manual: true }
    );

    function handleUserLogin(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        executeLogin({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                u: user,
                p: password
            })
        });
    }

    function autoUserLogin() {
        executeLogin({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                u: storedUser,
                t: storedToken
            })
        });
    }

    useEffect(() => {
        if (storedToken && storedUser) {
            autoUserLogin();
        }
    }, []);

    useEffect(() => {
        if (storedToken) {
            setUser(storedUser);
        }
    }, [storedUser]);

    useEffect(() => {
        if (userData) {
            if (userData.error) {
                console.error(userData);
            } else {
                setStoredToken(userData.token);
                propsSetCustomer(userData.customer, userData.token);
                propagateUser(userData.customer);
                setStoredUser(user);
                if (resettoken) {
                    history.push('/reset-password');
                } else {
                    history.push('/dashboard');
                }
            }
        }
    }, [userData, propsSetCustomer, history]);

    useEffect(() => {
        if (token || resettoken) {
            executeLogin({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'x-sb-token': token || resettoken
                }
            });
        }
    }, []);

    return (
        <section className={`Login`}>
            {[
                loading && <Loading key="loading" />,
                userData?.error &&
                (token ? (
                    <div className="error">{t(`login.tokenError.${userData?.error}`)}</div>
                ) : userData?.error === 'autoLoginFailed' ? '' : (
                    <div className="error">{t(`login.error.${userData?.error}`)}</div>
                )),
                !loading && (!userData || userData.error) && (
                    <form noValidate autoComplete="off" key="loginForm" onSubmit={handleUserLogin}>
                        <TextField id="user" label={t('login.user')} value={user} onChange={storeString(setUser)} />
                        <TextField
                            type="password"
                            id="password"
                            label={t('login.password')}
                            value={password}
                            onChange={storeString(setPassword)}
                        />
                        <Button variant="contained" color="primary" type="submit" disabled={!user || !password}>
                            {t('login.submit')}
                        </Button>
                    </form>
                ),
                !loading && (
                    <div key="toRegister" className="toRegister">
                        <Translate contentKey="login.noAccount" />
                        <Button variant="contained" type="button" onClick={() => history.push('register')}>
                            {t('login.toRegister')}
                        </Button>
                    </div>
                ),
                !loading && (
                    <div key="forgotPassword" className="forgotPassword">
                        <div>
                            <Translate contentKey="login.forgotPassword.text" />
                        </div>
                        <Button variant="contained" type="button" onClick={() => history.push('forgot-password')}>
                            {t('login.forgotPassword.action')}
                        </Button>
                    </div>
                )
            ]}
        </section>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({});

const mapDispatchToProps = {
    setCustomer
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
