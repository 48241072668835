import useAxios from 'axios-hooks';
import qs from 'querystring';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Loading } from '../shared/loading/loading';
import UserData, { IUserDataErrors } from '../shared/user-data/user-data';
import { IRootState } from '../store/_root.reducer';
import { Customer, setCustomer } from '../store/app.reducer';
import RequestTeachersAccountTeaser from '../shared/request-teachers-account/request-teachers-account-teaser';
import { propagateUser } from '../utils/utils';

import './user-update.scss';

interface IUserUpdateProps extends StateProps, DispatchProps, RouteComponentProps {}

export const UserUpdate: FunctionComponent<IUserUpdateProps> = props => {
    const { t } = useTranslation();
    const { app, setCustomer: propsSetCustomer } = props;
    const { customer, token } = app;
    const [changedCustomer, setChangedCustomer] = useState<Partial<Customer>>({});
    const [errors, setErrors] = useState<IUserDataErrors>({});

    const [{ loading, data: userData, error }, executeUpdateUserData] = useAxios(
        {
            url: 'update.php',
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        if (userData) {
            const _customer = {
                ...customer,
                ...userData.customer
            };
            propsSetCustomer(_customer, token);
            propagateUser(_customer);
        }
    }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error) {
            const errorCode = error?.response?.data?.error;
            let _errors: IUserDataErrors = {};

            const interpolate = {
                email: changedCustomer.email,
                nickName: changedCustomer.nickname
            };

            if (errorCode === 'duplicate email or nickname') {
                _errors = {
                    ..._errors,
                    email: t(`userData.errors.api.${errorCode}`, interpolate),
                    nickName: t(`userData.errors.api.${errorCode}`, interpolate)
                };
            }

            if (errorCode === 'unauthorized  old password failed') {
                _errors = {
                    ..._errors,
                    api: t(`userData.errors.api.${errorCode}`)
                };
            }
            setErrors(_errors);
        }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleSubmit(customer: Partial<Customer>) {
        setChangedCustomer(customer);
        submitUserData(customer);
    }

    const submitUserData = (customer: Partial<Customer>) => {
        executeUpdateUserData({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                ...customer
            })
        });
    };

    return (
        <>
            {loading && <Loading />}
            {!loading && !!customer && (
                <section className={`UserUpdate`}>
                    {!!userData && <h4>{t('userData.update.success')}</h4>}
                    {!!errors?.api && <div className="apiError">{errors.api}</div>}
                    <UserData customer={customer} context="update" apiErrors={errors} onSubmit={handleSubmit} />
                    <RequestTeachersAccountTeaser />
                </section>
            )}
        </>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    setCustomer
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserUpdate));
