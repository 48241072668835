import { Button, Link } from '@material-ui/core';
import useAxios from 'axios-hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './manage-vip.scss';

declare const Stripe: any;

interface StripeCredentials {
    session_id: string;
    stripe_key: string;
}

interface IManageVipProps {
    isVip: boolean;
}

export const ManageVip: FunctionComponent<IManageVipProps> = props => {
    const { isVip } = props;
    const { t } = useTranslation();
    const [{ loading, data }, fetchStripeCredentials] = useAxios<string>(
        {
            url: 'https://api.onlinebridgeclub.com/customer/stripecheckout.php',
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        console.log('stripe reposnse ', data);
        if (data && !loading) {
            window.location.href = data;
        }
    }, [loading, data]);

    return (
        <section className="OrderCredits">
            <Button variant="contained" color="secondary" className="orderCredits" onClick={() => fetchStripeCredentials()}>
                {t(isVip ? 'dashboard.manageVip' : 'dashboard.getVip')}
            </Button>
        </section>
    );
};

export default ManageVip;
