import { Button, Divider, Menu, MenuItem } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import useLocalStorage from 'react-use-localstorage';

import { IRootState } from '../../../store/_root.reducer';
import { resetApp } from '../../../store/app.reducer';
import { propagateUser } from '../../../utils/utils';

import './customer.scss';

interface ICustomerProps extends StateProps, DispatchProps {}

export const Customer: FunctionComponent<ICustomerProps> = props => {
    const { app } = props;
    const { customer } = app;
    const [customerAnchor, setCustomerAnchor] = React.useState<null | HTMLElement>(null);
    const [storedToken, setStoredToken] = useLocalStorage('bc-token', undefined);

    const handleCustomerClick = (event: any) => {
        setCustomerAnchor(event.currentTarget);
    };

    const handleCustomerClose = () => {
        setCustomerAnchor(null);
    };

    const handleLogout = () => {
        setStoredToken('');
        propagateUser();
        props.resetApp();
    };

    const options = [
        <Button onClick={handleLogout} size="small" variant="contained" color="primary">
            Logout
        </Button>
    ];

    return customer ? (
        <section className={`Customer`}>
            <div className="openCustomerMenu" onClick={handleCustomerClick}>
                {customer.nickname} • {customer.firstname} {customer.lastname}
            </div>
            {/*<Button*/}
            {/*  onClick={handleCustomerClick}*/}
            {/*  size="small"*/}
            {/*  variant="contained"*/}
            {/*  color="primary"*/}
            {/*>*/}
            {/*  {customer.nickname} • {customer.firstname} {customer.lastname}*/}
            {/*</Button>*/}
            <Menu
                className="customerMenu"
                anchorEl={customerAnchor}
                keepMounted
                open={Boolean(customerAnchor)}
                onClose={handleCustomerClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <MenuItem disabled />
                {options.map((option, index) => [
                    index !== 0 && <Divider />,
                    <div className="MuiMenuItem-root MuiListItem-gutters">{option}</div>
                ])}
            </Menu>
        </section>
    ) : null;
};

const mapStateToProps = ({ app }: IRootState) => ({
    app
});

const mapDispatchToProps = {
    resetApp
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
